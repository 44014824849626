<template>
	<div class="wrapper">
		<div class="main-container mt-5">
			<div class="row pl-4">
				<div class="col-10 px-0">
					<div class="d-flex">
						<h1>{{ issue.title }}</h1>
						<h1 class="text-dark-50 ml-2 font-weight-light">#{{ issue.id }}</h1>
					</div>
					<div class="d-flex align-items-center">
						<b-button :variant="issue.status == 'open' ? 'success' : 'info'" size="sm" style="border-radius: 20px;">
							<div class="svg-icon svg-icon-white">
								<inline-svg
									:src="issue.status == 'open' ? '/media/svg/icons/Media/Vynil.svg' : '/media/svg/icons/Code/Done-circle.svg'"
									width="20"
									class="mr-1"
								/>
								<span style="text-transform:capitalize;">{{ issue.status }}</span>
							</div>
						</b-button>
						<div class="ml-2">{{ issue.name }} opened this issue on {{ formatDate(issue.created, true) }}</div>
					</div>
				</div>
				<div class="col-2 d-flex align-items-end justify-content-end">
					<b-button :to="{ name: 'editissue', params: { id: issue.id } }" variant="outline-secondary" size="sm" class="mr-2">Edit Issue</b-button>
					<b-button :to="{ name: 'issues' }" variant="outline-success" size="sm">Back to List</b-button>
				</div>
			</div>
			<hr />
			<div class="row">
				<div class="col-8">
					<div class="comments-box">
						<div class="line"></div>
						<div class="card">
							<div class="d-flex align-items-center p-4">
								<span class="font-weight-bolder">{{ issue.name }} </span>
								<span class="mx-1 text-dark-75">({{ issue.department }} - {{ issue.region }})</span>
								<span>commented on {{ formatDate(issue.created) }}</span>
							</div>
							<hr class="m-0" />
							<div class="p-4">
								<div class="mb-3">
									<div class="font-weight-bold">Issue Type</div>
									{{ issue.type }}
								</div>
								<div class="mb-3">
									<div class="font-weight-bold">Severity</div>
									{{ issue.severity }}
								</div>
								<div class="mb-3">
									<div class="font-weight-bold">Project</div>
									{{ issue.project }}
								</div>
								<div v-if="issue.expected" class="mb-3">
									<div class="font-weight-bold">Expected</div>
									{{ issue.expected }}
								</div>
								<div v-if="issue.actual" class="mb-3">
									<div class="font-weight-bold">Actual</div>
									{{ issue.actual }}
								</div>
								<div class="mb-3">
									<div class="font-weight-bold">Description</div>
									{{ issue.description }}
								</div>
								<div>
									<span class="font-weight-bold">Link:</span> <a :href="issue.link">{{ issue.link }}</a>
								</div>
							</div>
						</div>
						<!-- COMMENT TIMELINE -->
						<template v-for="comment in issue.comments">
							<!-- ASSIGN -->
							<div :key="comment.id" v-if="comment.type == 'assign'" class="assigned-comment my-8 d-flex align-items-center">
								<i class="flaticon-user text-info mr-2"></i>
								<span class="font-weight-bold text-info mr-1">{{ comment.comment }} was assigned to the issue </span> on {{ comment.timestamp }}
							</div>
							<div :key="comment.id" v-if="comment.type == 'unassign'" class="assigned-comment my-8 d-flex align-items-center">
								<i class="flaticon-user text-warning mr-2"></i>
								<span class="font-weight-bold text-warning mr-1">{{ comment.comment }} was unassigned to the issue </span> on {{ comment.timestamp }}
							</div>
							<!-- SEVERITY -->
							<div :key="comment.id" v-else-if="comment.type == 'severity'" class="jira-comment my-8 d-flex align-items-center">
								<div
									class="svg-icon"
									:class="{
										'svg-icon-danger': comment.comment == 'High',
										'svg-icon-warning': comment.comment == 'Medium',
										'svg-icon-info': comment.comment == 'Low',
									}"
								>
									<inline-svg src="/media/svg/icons/Communication/Flag.svg" width="18" class="mr-2" />
								</div>
								<span
									class="font-weight-bold mr-1"
									:class="{
										'text-danger': comment.comment == 'High',
										'text-warning': comment.comment == 'Medium',
										'text-info': comment.comment == 'Low',
									}"
								>
									The severity level was changed to {{ comment.comment }}
								</span>
								on {{ comment.timestamp }}
							</div>
							<!-- JIRA -->
							<div :key="comment.id" v-else-if="comment.type == 'jira'" class="jira-comment my-8 d-flex align-items-center">
								<inline-svg src="/media/svg/custom/jira.svg" width="20" class="mr-2" />
								<span class="font-weight-bold text-primary mr-1">
									<a :href="'http://192.168.10.22:8081/browse/' + comment.comment">{{ comment.comment }} - A new Jira issue was created</a>
								</span>
								on {{ comment.timestamp }}
							</div>
							<!-- CLOSED -->
							<div :key="comment.id" v-else-if="comment.type == 'closed'" class="jira-comment my-8 d-flex align-items-center">
								<div class="svg-icon svg-icon-danger">
									<inline-svg src="/media/svg/icons/Code/Done-circle.svg" width="20" class="mr-1" />
								</div>
								<span class="font-weight-bold text-danger mr-1"> {{ comment.name }} closed this on {{ comment.resolved }} </span>
								on {{ formatDate(comment.timestamp, true) }}
							</div>
							<!-- COMMENT CARD -->
							<div :key="comment.id" v-else-if="comment.type == 'comment'" class="my-8">
								<div class="card">
									<div class="d-flex align-items-center p-4">
										<span class="font-weight-bolder">{{ comment.name }} </span>
										<span class="mx-1 text-dark-75">({{ comment.department }} - {{ comment.region }})</span>
										<span>commented on {{ formatDate(comment.timestamp) }}</span>
									</div>
									<hr class="m-0" />
									<div class="p-4">{{ comment.comment }}</div>
								</div>
							</div>
						</template>
					</div>
					<hr />
					<div class="card">
						<div class="p-3">
							<b-form-textarea rows="5" v-model="newComment" placeholder="Leave a comment"></b-form-textarea>
						</div>
						<div class="p-3 text-right">
							<b-button variant="outline-success" class="mr-3" @click="close">Close</b-button>
							<b-button variant="success" @click="addComment">Comment</b-button>
						</div>
					</div>
				</div>

				<!-- RIGHT SIDE INFO SECTION -->
				<div class="col-4">
					<div class="info-section">
						<div class="settings" v-if="admin">
							<b-dropdown variant="link" no-caret right lazy>
								<template #button-content>
									<span class="svg-icon">
										<inline-svg src="/media/svg/icons/Shopping/Settings.svg"></inline-svg>
									</span>
								</template>
								<b-dropdown-header class="settings-header">Assign Dev</b-dropdown-header>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item v-for="developer in developers" :key="developer || 1" @click="assign(developer)">
									<div class="d-flex align-items-center">
										<div style="width: 30px">
											<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" class="closed-icon" v-if="issue.developer == developer" />
										</div>
										<div>{{ developer || 'Unassigned' }}</div>
									</div>
								</b-dropdown-item>
							</b-dropdown>
						</div>
						<div class="font-weight-bold my-3">Assignees</div>
						<div>
							<div v-if="issue.developer">
								{{ issue.developer }}
							</div>
							<div v-else>
								<span v-if="!admin">No one assigned</span>
								<span v-else class="side-link" @click="assign('current')">Assign yourself</span>
							</div>
						</div>
						<hr />
					</div>
					<div class="info-section">
						<div class="settings" v-if="admin">
							<b-dropdown variant="link" no-caret right lazy>
								<template #button-content>
									<span class="svg-icon">
										<inline-svg src="/media/svg/icons/Shopping/Settings.svg"></inline-svg>
									</span>
								</template>
								<b-dropdown-header class="settings-header">Add Label</b-dropdown-header>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item v-for="type in types" :key="type" @click="updateProp('type', type)">
									<div class="d-flex align-items-center">
										<div style="width: 30px">
											<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="issue.type == type" />
										</div>
										<div>{{ type }}</div>
									</div>
								</b-dropdown-item>
							</b-dropdown>
						</div>
						<div class="font-weight-bold my-3">Labels</div>
						<span
							class="label label-rounded label-inline font-weight-bolder"
							:class="{
								'label-danger': issue.type == 'Bug',
								'label-success': issue.type == 'Feature',
								'label-info': issue.type == 'Report',
								'label-primary': issue.type == 'Info',
								'label-secondary': issue.type == 'Other',
							}"
						>
							{{ issue.type }}
						</span>
						<hr />
					</div>
					<div class="info-section">
						<div class="settings" v-if="admin">
							<b-dropdown variant="link" no-caret right lazy>
								<template #button-content>
									<span class="svg-icon">
										<inline-svg src="/media/svg/icons/Shopping/Settings.svg"></inline-svg>
									</span>
								</template>
								<b-dropdown-header class="settings-header">Set Severity</b-dropdown-header>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item v-for="s in severity" :key="s" @click="updateSeverity(s)">
									<div class="d-flex align-items-center">
										<div style="width: 30px">
											<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="issue.severity == s" />
										</div>
										<div>{{ s }}</div>
									</div>
								</b-dropdown-item>
							</b-dropdown>
						</div>
						<div class="font-weight-bold my-3">Severity</div>
						<span
							class="label label-rounded label-inline font-weight-bolder"
							:class="{
								'label-danger': issue.severity == 'High',
								'label-warning': issue.severity == 'Medium',
								'label-info': issue.severity == 'Low',
							}"
						>
							{{ issue.severity }}
						</span>
						<hr />
					</div>
					<div class="info-section">
						<div class="settings" v-if="admin">
							<b-dropdown variant="link" no-caret right lazy>
								<template #button-content>
									<span class="svg-icon">
										<inline-svg src="/media/svg/icons/Shopping/Settings.svg"></inline-svg>
									</span>
								</template>
								<b-dropdown-header class="settings-header">Set Project</b-dropdown-header>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-item v-for="project in projects" :key="project" @click="updateProp('project', project)">
									<div class="d-flex align-items-center">
										<div style="width: 30px">
											<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="issue.project == project" />
										</div>
										<div>{{ project }}</div>
									</div>
								</b-dropdown-item>
							</b-dropdown>
						</div>
						<div class="font-weight-bold my-3">Project</div>
						<div>{{ issue.project }}</div>
						<hr />
					</div>
					<div class="info-section">
						<div class="settings" v-if="admin">
							<b-dropdown ref="jiraDropdown" variant="link" no-caret right lazy>
								<template #button-content>
									<span class="svg-icon">
										<inline-svg src="/media/svg/icons/Shopping/Settings.svg"></inline-svg>
									</span>
								</template>
								<b-dropdown-header class="settings-header">Add Jira ID</b-dropdown-header>
								<b-dropdown-divider></b-dropdown-divider>
								<b-dropdown-form>
									<b-form @submit.stop.prevent="addJira">
										<div class="d-flex">
											<b-form-input size="sm" type="text" id="jira" class="mr-2" v-model="jiraId" placeholder="Jira Issue ID" :autofocus="true" />
											<b-button size="sm" variant="success" class="px-4" @click="addJira">Save</b-button>
										</div>
									</b-form>
								</b-dropdown-form>
							</b-dropdown>
						</div>
						<div class="font-weight-bold my-3">Jira Issue</div>
						<a :href="'http://192.168.10.22:8081/browse/' + issue.jira">{{ issue.jira }}</a>
						<hr />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'Issue',
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			issue: [],
			loaded: false,
			showJiraInput: false,
			jiraId: null,
			developers: ['Josh Palacios', 'Paul Lime', ''],
			types: ['Bug', 'Feature', 'Report', 'Info', 'Other'],
			severity: ['Low', 'Medium', 'High'],
			projects: ['VSAT Portal', 'NOC Portal', 'Wallboard', 'CM4K'],
			newComment: null,
		};
	},
	computed: {
		...mapGetters(['currentUser']),
		admin() {
			return ['jpalacios', 'limep', 'jimenezj'].includes(this.currentUser.username);
		},
	},
	methods: {
		assign(user) {
			this.issue.developer = user;
			if (user == 'current') {
				if (this.currentUser.username == 'jpalacios') {
					this.issue.developer = 'Josh Palacios';
				} else if (this.currentUser.username == 'limep') {
					this.issue.developer == 'Paul Lime';
				}
			}
			this.$http.put(`issue/${this.id}/assign`, { developer: this.issue.developer }).then(({ data }) => {
				if (data.status == 200) {
					this.issue.comments.push(data.comment);
				}
			});
		},
		updateProp(key, val) {
			this.$http.put(`issue/${this.id}`, { [key]: val }).then(({ data }) => {
				if (data.status == 200) {
					this.issue[key] = data.data[key];
				}
			});
		},
		updateSeverity(level) {
			this.$http.put(`issue/${this.id}/severity`, { severity: level }).then(({ data }) => {
				if (data.status == 200) {
					this.issue.severity = level;
					this.issue.comments.push(data.comment);
				}
			});
		},
		addJira() {
			this.$refs.jiraDropdown.hide();
			this.$http.put(`issue/${this.id}/jira`, { jira: this.jiraId }).then(resp => {
				this.issue.jira = this.jiraId;
				this.issue.comments.push(data.comment);
			});
		},
		addComment() {
			this.$http.post(`issue/${this.id}/comment`, { comment: this.newComment }).then(resp => {
				console.log(resp);
			});
		},
		close() {
			this.$http.post(`issue/${this.id}/close`, { comment: this.newComment }).then(resp => {
				if (resp.data.status == 200) {
					resp.data.comments.forEach(comment => {
						this.issue.comments.push(comment);
					});
					this.issue.status = 'closed';
					this.newComment = '';
				}
			});
		},
		formatDate(timestamp, time = false) {
			let d = new Date(timestamp + 'Z');
			if (time) {
				return d.toLocaleString('en-US', {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
					timeZoneName: 'short',
				});
			}
			return d.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
		},
	},
	created() {
		this.loaded = false;
		this.$http.get(`issue/${this.id}`).then(resp => {
			this.issue = resp.data;
			this.loaded = true;
		});
	},
};
</script>

<style scoped lang="scss">
.wrapper {
	background: white;
	display: flex;
	justify-content: center;
	height: 100%;
}
.main-container {
	max-width: 1200px;
	width: 100%;
	padding: 15px 0;
}
.comments-box {
	position: relative;
}
.line {
	position: absolute;
	top: 0;
	left: 35px;
	height: 100%;
	width: 1px;
	background: #ebedf3;
}
.assigned-comment {
	margin-left: 28px;
	position: relative;
	i {
		background: white;
	}
}
.jira-comment {
	margin-left: 26px;
	position: relative;
	svg {
		background: white;
	}
}
.side-link:hover {
	color: $primary;
	cursor: pointer;
}
.info-section {
	position: relative;
}
.settings {
	position: absolute;
	top: 0px;
	right: 10px;

	::v-deep .dropdown button {
		padding: 0;
		text-align: right;

		.svg-icon {
			margin: 0;
		}
	}
	::v-deep .settings-header header {
		font-weight: 500;
		color: black;
		width: 300px;
	}
}
</style>
